import { FC, SetStateAction } from "react";
import { NavType } from "../../Shell";
import { useLocation, useNavigate } from "react-router-dom";

type NavItemProps = {
  nav: NavType;
  setOpened?: React.Dispatch<SetStateAction<boolean>>;
};

export const NavItem: FC<NavItemProps> = ({ nav, setOpened }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const Icon = nav.icon;
  return (
    <div
      className={`flex justify-center cursor-pointer space-x-2 ml-10 text-2xl font-planewalker
      ${
        location.pathname === "/" + nav.to ? "text-blue-700" : "text-gray-800"
      } hover:text-blue-700 transition-200`}
      onClick={() => {
        navigate(`/${nav.to}`);
        if (typeof setOpened != "undefined") {
          setOpened(false);
        }
      }}
    >
      {/* <Icon className="mt-1" /> */}
      <div>{nav.label}</div>
    </div>
  );
};
