export const Learn = () => {
  return (
    <div className="flex flex-col items-center justify-center text-2xl w-full font-planewalker">
      <div className="flex flex-col w-5/6 lg:w-1/2 space-y-5 text-justify">
        <div className="">
          You are a <span className="font-bold">Traveler</span> in a deep space
          journey to <span className="font-bold">Omega</span>, a planet in the
          outskirts of the known Universe.
        </div>
        <div className="">
          For this long journey, your body has been cryo-frozen, yet your brain
          stays awake to avoid its decay. You remain connected with other{" "}
          <span className="font-bold">Travelers</span> in the ship to play a
          game: <span className="font-bold">Waiting for Planet Omega</span>.
        </div>
        <div className="">
          Outsmart other <span className="font-bold">Travelers</span> with a
          dance in the moment between <span className="font-bold">Future</span>{" "}
          and <span className="font-bold">Past</span>. Use your{" "}
          <span className="font-bold">Memories</span> to think complex{" "}
          <span className="font-bold">Thoughts</span>, while managing the{" "}
          <span className="font-bold">Stress</span> from{" "}
          <span className="font-bold">Neurosis</span> and Time itself.
        </div>
      </div>
    </div>
  );
};
