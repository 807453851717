import { Carousel } from "@mantine/carousel";
import { SlideCard } from "./components/SlideCard";
import { CarouselForm } from "../../components/CarouselForm";
export const Landing = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-3/4 border h-fit">
        <CarouselForm
          slides={[
            <SlideCard name={"REVERSO"} />,
            <SlideCard name={"A_Moment_Of_Bliss"} />,
            <SlideCard name={"A_Wizards_Affair"} />,
            <SlideCard name={"Featherwisp"} />,
            <SlideCard name={"Ayna"} />,
            <SlideCard name={"Cadence,_Rewind"} />,
            <SlideCard name={"Tit_&_Tat,_Iconic_Duo"} />,
            <SlideCard name={"Fall_Warp_Qu"} />,
          ]}
          breakpoints={[
            {
              maxWidth: "2xl",
              slideSize: "65%",
              slideGap: "10",
            },
            { maxWidth: "lg", slideSize: "55%", slideGap: "10" },
            {
              maxWidth: "md",
              slideSize: "60%",
              slideGap: "5",
            },
            {
              maxWidth: "sm",
              slideSize: "100%",
              slideGap: "0",
            },
          ]}
        />
      </div>
    </div>
  );
};
