import { FC, useState, SetStateAction } from "react";
import { AppNavbar } from "./Navbar/AppNavbar";
import { AppHeader } from "./AppHeader";
import { AppFooter } from "./AppFooter";
import { Routing } from "../Routing/Routing";
import { FaTools, FaSmile } from "react-icons/fa";
import { IoNewspaperSharp } from "react-icons/io5";
import { BsFillPersonLinesFill } from "react-icons/bs";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  MediaQuery,
  Burger,
} from "@mantine/core";
import type { IconType } from "react-icons";
import { HorizontalNavbar } from "./Navbar/HorizontalNavbar";
import { RiArticleFill } from "react-icons/ri";

type ShellProps = {
  // navigation: NavType[];
};

export interface NavType {
  icon: IconType;
  label: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string }[];
  to?: string;
}
export type NavbarProps = {
  //   navigation: NavType[];
  setOpened?: React.Dispatch<SetStateAction<boolean>>;
  navbarRoutes: NavType[];
};
const navbarRoutes: NavType[] = [
  {
    label: "Learn to Play",
    icon: BsFillPersonLinesFill,
    to: "learn",
  },
  {
    label: "Cards",
    icon: FaTools,
    to: "cards",
  },

  // {
  //   label: "Enterprise",
  //   icon: IoNewspaperSharp,
  //   to: "organizations",
  // },
  // {
  //   label: "Team",
  //   icon: FaSmile,
  //   to: "team",
  // },
  // {
  //   label: "Blog",
  //   icon: RiArticleFill,
  //   to: "blog",
  // },
];

export const Shell: FC<ShellProps> = ({}) => {
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      // styles={}
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          // className="block sm:hidden"
          p="md"
          hiddenBreakpoint={100000} // this makes it so vertical navbar is hidden other than in sm
          hidden={!opened}
          width={{ sm: 0, md: 0 }}
        >
          <AppNavbar setOpened={setOpened} navbarRoutes={navbarRoutes} />
        </Navbar>
      }
      footer={
        <Footer height={40} p="md">
          <AppFooter />
        </Footer>
      }
      header={
        <Header
          className="flex justify-center w-full"
          height={180}
          p="md"
          withBorder={false}
        >
          <div className="flex flex-row w-full p-5">
            <AppHeader />
            <div
              className={`flex justify-end w-0 md:w-1/2 ${
                opened ? "hidden" : ""
              } mr-10 `}
            >
              <HorizontalNavbar navbarRoutes={navbarRoutes} />
              {/* <ContactButton /> */}
            </div>

            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                // color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
          </div>
        </Header>
      }
    >
      {/* Routing to App content */}
      <div className="content">
        <Routing />
      </div>
    </AppShell>
  );
};
