export const cardsAlphaSorted = {
  travellers: ["Gordon", "Hilda", "Ayna", "Chane"],
  redNeurose: [
    "Sylvio_Two_Daggers",
    "Tenpura,_Immanuel_Judge",
    "Tit_&_Tat,_Iconic_Duo",
    "Fiasco,_Guilty_Party",
    "Cyz,_A_Thousand_Strikes",
    "Keyron_Mercurial",
  ],
  redThoughts: [
    "A_Fools_Deal",
    "Extract_Memories",
    "Morning_Routine",
    "Become_The_Within",
    "Underworld_Bureocracy",
    "Hostage_Shield",
    "Start_Anew",
    "Lone_Sinner",
    "Teleport!",
    "Brunch",
    "One-Punch_Golem",
    "Brass_Knuckles",
    "Red_Cerise_Tangó",
    "Featherwisp",
    "Profane_Poetry",
    "Got_Yo_Nose!",
    "Bureocratic_Hobgoblin",
    "Late_Night_Interloper",
    "Painful_Repentment",
    "A_Moment_Of_Rage",
    "Cheeky_Bite",
    "Flaccidity",
    "Ghastly_Séance",
    "Valkyrja_Danseur",
    "Eternal_Youth",
    "Hellhound_Prance",
    "Landlord_From_The_Underworld",
    "Blood_Blessing",
  ],
  yellowNeurose: [
    "Ora,_Double_Agent",
    "G-Skuat",
    "Golos",
    "Punpun,_Adamantium_Charm",
    "The_Emperors_Daughter",
    "Fall_Warp_Qu",
  ],
  yellowThoughts: [
    "Omegas_Desert",
    "A_Wizards_Affair",
    "Sudden_Mercurial",
    "The_Emperors_Command",
    "La_Mano_De_Dios",
    "Sublation",
    "Reckless_Leap",
    "Swooning_At_Nihil",

    "Unit_Gizmo-Self",
    "Transcendent_Voyage",
    "Backlash!",
    "The_Gift_Of_An_A_Priori",
    "Expect_The_Unexpected!",
    "Beelzebubs_Birthday",
    "Misreunion_In_The_Unknown",
    "Nether_Portal",
    "Sisters_Interlinked",
    "Sleeping_Pill",
    "Thunderkick!",
    "Eroticist",
    "Rerouting_Trick",
    "Studied_Move",
    "Two-Step",
    "Three-Screws_Automaton",
    "Limitless_Jack-In",
    "Evil_Twin",
    "Brennschluss_Arabesque!",
    "The_Three_Dilemmas_Of_Beelzebub",
  ],
  blueNeurose: [
    "Teresa,_Tainted_Id",
    "Cadence,_Rewind",
    "Alma,_Axiom_Keeper",
    "Yumei,_Never_Approach",
    "Utopia,_Venal_Pedagogue",
    "Zigurath_Xii_(Broken)",
  ],
  blueThoughts: [
    "Memory_Mash",
    "Jacuzzi",
    "Desoeuvrement_Dryad",
    "Ghoul_Insurrection",
    "The_Hour_Of_Metempsychosis",
    "The_First_Commandment",
    "Double_Flip!",
    "Mataor",
    "Telekinesis",
    "Hyperrelaxation",

    "The_Illusionists_Way",
    "Finale_Of_Melancholy",
    "Otherworldly_Aid",
    "The_Eye_Of_Gorudo",
    "Teatime_At_Omegas_Fields",
    "Tug_Of_War",
    "Chimera_Inc",
    "Silence",
    "Deth",
    "Two-Eyed_Cyclops",
    "Mindpoke_600_Tm",
    "Gorudos_Lake",

    "Faerie_Light_Exorcist",
    "Crypticity",
    "Soothing_Words",
    "Onanismo",
    "Indifference",
  ],
  multicolor: ["REVERSO", "Quantum_At_Omega", "Silent_Confession"],
};

export const cardsAlpha = [
  "Ora,_Double_Agent",
  "Memory_Mash",
  "Extract_Memories",
  "Underworld_Bureocracy",
  "Ghoul_Insurrection",
  "The_Emperors_Command",
  "Ghastly_Séance",
  "Hostage_Shield",
  "Reckless_Leap",
  "The_Eye_Of_Gorudo",
  "Start_Anew",
  "Silence",
  "Teleport!",
  "Brunch",
  "Misreunion_In_The_Unknown",
  "Lone_Sinner",
  "Expect_The_Unexpected!",
  "Teresa,_Tainted_Id",
  "A_Fools_Deal",
  "Valkyrja_Danseur",
  "Fall_Warp_Qu",
  "Cyz,_A_Thousand_Strikes",
  "La_Mano_De_Dios",
  "Hyperrelaxation",
  "Fiasco,_Guilty_Party",
  "Telekinesis",
  "Mataor",
  "The_Emperors_Daughter",
  "Yumei,_Never_Approach",
  "Double_Flip!",
  "Swooning_At_Nihil",
  "Limitless_Jack-In",
  "Got_Yo_Nose!",
  "Onanismo",
  "Crypticity",
  "A_Wizards_Affair",
  "Blood_Blessing",
  "Keyron_Mercurial",
  "Tug_Of_War",
  "Chane",
  "Nether_Portal",
  "Cadence,_Rewind",
  "Desoeuvrement_Dryad",
  "Zigurath_Xii_(Broken)",
  "Become_The_Within",
  "Red_Cerise_Tangó",
  "Jacuzzi",
  "Morning_Routine",
  "REVERSO",
  "Tenpura,_Immanuel_Judge",
  "Thunderkick!",
  "Brennschluss_Arabesque!",
  "Landlord_From_The_Underworld",
  "Hellhound_Prance",
  "Chimera_Inc",
  "Eternal_Youth",
  "The_Three_Dilemmas_Of_Beelzebub",
  "Punpun,_Adamantium_Charm",
  "Evil_Twin",
  "Featherwisp",
  "Late_Night_Interloper",
  "The_Illusionists_Way",
  "Backlash!",
  "Soothing_Words",
  "Faerie_Light_Exorcist",
  "Otherworldly_Aid",
  "Three-Screws_Automaton",
  "Gorudos_Lake",
  "Cheeky_Bite",
  "Two-Step",
  "Painful_Repentment",
  "Mindpoke_600_Tm",
  "Transcendent_Voyage",
  "Teatime_At_Omegas_Fields",
  "Sublation",
  "Unit_Gizmo-Self",
  "Finale_Of_Melancholy",
  "Omegas_Desert",
  "A_Moment_Of_Bliss",
  "Beelzebubs_Birthday",
  "Tit_&_Tat,_Iconic_Duo",
  "The_First_Commandment",
  "A_Moment_Of_Rage",
  "Alma,_Axiom_Keeper",
  "Indifference",
  "Silent_Confession",
  "Bureocratic_Hobgoblin",
  "Golos",
  "The_Gift_Of_An_A_Priori",
  "Sudden_Mercurial",
  "The_Hour_Of_Metempsychosis",
  "Ayna",
  "Studied_Move",
  "G-Skuat",
  "Rerouting_Trick",
  "Flaccidity",
  "Eroticist",
  "Two-Eyed_Cyclops",
  "Sylvio_Two_Daggers",
  "Sisters_Interlinked",
  "Sleeping_Pill",
  "Profane_Poetry",
  "Hilda",
  "Gordon",
  "Quantum_At_Omega",
  "Brass_Knuckles",
  "One-Punch_Golem",
  "Deth",
  "Utopia,_Venal_Pedagogue",
];
