import { Pagination, Tabs } from "@mantine/core";
import { useState, useEffect } from "react";
import { GiSleepingBag, GiFishMonster } from "react-icons/gi";
import { IoMdPlanet } from "react-icons/io";
import { BsCircleHalf } from "react-icons/bs";
import { blue, yellow, red } from "../../components/constants";
import { CardsTabPanel } from "./components/CardsTabPanel";
import { WiStars } from "react-icons/wi";

export const Cards = () => {
  const [activePage, setPage] = useState(1);
  const [cardsToShow, setCardsToShow] = useState<string[]>([]);

  const [activeTab, setActiveTab] = useState<string | null>("travellers");
  return (
    <div className="flex-col items-center font-planewalker text-2xl w-full text-center">
      <div className="mb-10">Take a look at our Alpha card gallery!</div>
      <Tabs
        defaultValue="travellers"
        value={activeTab}
        onTabChange={setActiveTab}
      >
        <Tabs.List position="center">
          <Tabs.Tab
            value="travellers"
            icon={<IoMdPlanet className="mb-2" size="2rem" />}
          >
            <span className="font-planewalker text-2xl">Travellers</span>
          </Tabs.Tab>
          {/* BLUE */}
          <Tabs.Tab
            value="blueNeurose"
            color="blue"
            icon={<GiFishMonster className="mb-2" size="2rem" color={blue} />}
          >
            <span className="font-planewalker text-2xl">Neurose</span>
          </Tabs.Tab>
          <Tabs.Tab
            value="blueThoughts"
            color="blue"
            icon={<BsCircleHalf className="mb-1" size="1.8rem" color={blue} />}
          >
            <span className="font-planewalker text-2xl">Thoughts</span>
          </Tabs.Tab>
          {/* YELLOW */}
          <Tabs.Tab
            value="yellowNeurose"
            color="yellow"
            icon={<GiFishMonster className="mb-2" size="2rem" color={yellow} />}
          >
            <span className="font-planewalker text-2xl">Neurose</span>
          </Tabs.Tab>
          <Tabs.Tab
            value="yellowThoughts"
            color="yellow"
            icon={
              <BsCircleHalf className="mb-1" size="1.8rem" color={yellow} />
            }
          >
            <span className="font-planewalker text-2xl">Thoughts</span>
          </Tabs.Tab>
          {/* RED */}
          <Tabs.Tab
            value="redNeurose"
            color="red"
            icon={<GiFishMonster className="mb-2" size="2rem" color={red} />}
          >
            <span className="font-planewalker text-2xl">Neurose</span>
          </Tabs.Tab>
          <Tabs.Tab
            value="redThoughts"
            color="red"
            icon={<BsCircleHalf className="mb-1" size="1.8rem" color={red} />}
          >
            <span className="font-planewalker text-2xl">Thoughts</span>
          </Tabs.Tab>
          <Tabs.Tab
            value="multicolor"
            // color="red"
            icon={<WiStars className="mb-1" size="2rem" color="black" />}
          >
            <span className="font-planewalker text-2xl">Allgemein</span>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="travellers">
          <CardsTabPanel tab={"travellers"} />
        </Tabs.Panel>

        <Tabs.Panel value="redNeurose">
          <CardsTabPanel tab="redNeurose" />
        </Tabs.Panel>
        <Tabs.Panel value="redThoughts">
          <CardsTabPanel tab="redThoughts" />
        </Tabs.Panel>

        <Tabs.Panel value="blueNeurose">
          <CardsTabPanel tab="blueNeurose" />
        </Tabs.Panel>
        <Tabs.Panel value="blueThoughts">
          <CardsTabPanel tab="blueThoughts" />
        </Tabs.Panel>

        <Tabs.Panel value="yellowNeurose">
          <CardsTabPanel tab="yellowNeurose" />
        </Tabs.Panel>
        <Tabs.Panel value="yellowThoughts">
          <CardsTabPanel tab="yellowThoughts" />
        </Tabs.Panel>
        <Tabs.Panel value="multicolor">
          <CardsTabPanel tab="multicolor" />
        </Tabs.Panel>
      </Tabs>
      {/* <Pagination value={activePage} onChange={setPage} total={10} /> */}
      {/* <div className="font-planewalker text-2xl w-full text-center">
        <img
          className=""
          src={
            "https://planetomegatcg-public.s3.eu-west-2.amazonaws.com/cards_alpha/" +
            cardsAlpha[0] +
            ".webp"
          }
          alt={cardsAlpha[0]}
        />
      </div> */}
    </div>
  );
};
