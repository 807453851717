import { Shell } from "./form/Shell/Shell";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export default function App() {
  const client = new QueryClient({});
  return (
    // MantineProvider is used to Change the theme of the app
    // I will use tailwindcss as allows for more flexible development (for me)
    // <MantineProvider withGlobalStyles withNormalizeCSS>
    // </MantineProvider>

    <BrowserRouter>
      <QueryClientProvider client={client}>
        <Shell />
      </QueryClientProvider>
    </BrowserRouter>
  );
}
