import { FC } from "react";
import { blue } from "../../components/constants";
import { useNavigate } from "react-router-dom";

export const AppHeader: FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-row cursor-pointer w-full items-center"
      onClick={() => {
        navigate(`/`);
      }}
    >
      <div className="w-24 mr-5">
        <img
          className=""
          src="https://planetomegatcg-public.s3.eu-west-2.amazonaws.com/website/logos/logo.png"
        ></img>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h1 className="w-full ml-5 font-planewalker font-bold tracking-wide from-neutral-900 text-2xl md:text-5xl">
          Waiting for Planet Omega
        </h1>
        <h1 className="w-full ml-5 font-planewalker font-bold text-blue-700 text-lg md:text-2xl mt-2">
          a card game of mind and time
        </h1>
      </div>
      {/* <div className="flex-col w-5/12 md:w-3/12 h-auto justify-center border max-w-sm cursor-pointer">
        <div className=" ">
          <img
            className="max-w-full h-auto"
            src={logo}
            alt="cortext"
            onClick={() => {
              navigate(`/about`);
            }}
          />
        </div>
        {/* invisible sm:visible 
        <div className="text-center text-sm sm:text-md md:text-lg text-red-700 font-mono">
          <TextShuffle text={"from language to sense"} />
        </div>
      </div> */}
    </div>
  );
};
