import { NavbarProps } from "../Shell";
import { NavItem } from "./components/NavItem";
import { FC } from "react";

export const HorizontalNavbar: FC<NavbarProps> = ({
  setOpened,
  navbarRoutes,
}) => {
  return (
    <div className="mt-3 hidden md:block">
      <div className="flex items-start justify-evenly lg:space-x-20 2xl:space-x-30">
        <>
          {navbarRoutes.map((item) => (
            <NavItem key={item.label} nav={item} setOpened={setOpened} />
          ))}
        </>
      </div>
    </div>
  );
};
