import { SimpleGrid } from "@mantine/core";
import { cardsAlphaSorted } from "./CardList";

export interface CardsTabPanelProps {
  tab: string;
}

export const CardsTabPanel = ({ tab }: CardsTabPanelProps) => {
  return (
    <div className="flex-col items-center mt-5">
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: "xl", cols: 4, spacing: "md" },
          { maxWidth: "lg", cols: 3, spacing: "md" },
          { maxWidth: "md", cols: 2, spacing: "sm" },
          { maxWidth: "sm", cols: 1, spacing: "sm" },
        ]}
      >
        {cardsAlphaSorted[tab as keyof typeof cardsAlphaSorted].map(
          (cardname: string) => {
            return (
              <img
                className=""
                src={
                  "https://planetomegatcg-public.s3.eu-west-2.amazonaws.com/cards_alpha/" +
                  cardname +
                  ".webp"
                }
                alt={cardname}
              />
            );
          }
        )}
      </SimpleGrid>
    </div>
  );
};
