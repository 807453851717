import { Carousel } from "@mantine/carousel";

interface CarouselProps {
  slides: any;
  breakpoints: any;
}

export const CarouselForm = ({ slides, breakpoints }: CarouselProps) => {
  return (
    <Carousel
      mx="auto"
      withIndicators
      slideGap="sm"
      align="center"
      slideSize={"35%"}
      loop
      breakpoints={breakpoints}
      styles={{
        indicator: {
          color: "red",
          width: 12,
          height: 4,
          transition: "width 250ms ease",

          "&[data-active]": {
            width: 40,
          },
        },
      }}
    >
      {slides.map((item: any) => (
        <Carousel.Slide>{item}</Carousel.Slide>
      ))}
    </Carousel>
  );
};
