import { FC } from "react";
import logo from "../../assets/logo192.png";

export const AppFooter: FC = () => {
  return (
    <div className="flex justify-center space-x-10 font-sans text-xs items-center -mt-2">
      <p>planetomegatcg@gmail.com</p>
      <img className="w-6 h-6" src={logo} alt="cortext" />
      <p>© Copyright 2022 by Planet Omega TCG</p>
    </div>
  );
};
