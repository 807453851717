interface SlideCardProps {
  name: string;
}
export const SlideCard = ({ name }: SlideCardProps) => {
  return (
    <div className="flex h-full items-center">
      <img
        className=""
        src={
          "https://planetomegatcg-public.s3.eu-west-2.amazonaws.com/cards_alpha/" +
          name +
          ".webp"
        }
        alt={name}
      />
    </div>
  );
};
