import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Landing } from "../../pages/Landing/Landing";
import { Cards } from "../../pages/Cards/Cards";
import { Learn } from "../../pages/Learn/Learn";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/cards" element={<Cards />} />
      <Route path="/learn" element={<Learn />} />
    </Routes>
  );
};
