import { FC } from "react";
import { NavItem } from "./components/NavItem";
import { NavbarProps } from "../Shell";

export const AppNavbar: FC<NavbarProps> = ({ setOpened, navbarRoutes }) => {
  return (
    <div className="flex-col space-y-10 mt-5">
      <>
        {navbarRoutes.map((item) => (
          <NavItem key={item.label} nav={item} setOpened={setOpened} />
        ))}
      </>
    </div>
  );
};
